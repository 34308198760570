import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function Home() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar variant="regular">
            <Typography variant="h6" color="inherit" component="div">
              Prakrti Remedies
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <div style={{ marginTop: "64px" }}></div>
      <div style={{ padding: "16px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 3, sm: 6, md: 12 }}
          >
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\furosemide.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Furosemide
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\amikashine.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Amikashine
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="/img/carnipark.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Carnipark
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\cystasave.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Cystasave
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\diclopark.png"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Diclopark
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\drotamack.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Drotamack
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\ondopark.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Ondopark
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\panpark.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Panpark
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\rabepark.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Rabepark
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\raberprazole.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Raberprazole
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
            <Grid item xs={3} sm={3} md={4}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="250"
                  image="\img\yatisone.jpg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Yatisone
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" variant="contained">
                    Buy
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
